class Engine {
  constructor() {
    this.eventListeners();
  }

  eventListeners() {
    const $window = $(window);
    const $body = $('html, body');
    const $backToTop = $('.back-to-top');

    $window.on('scroll', () => {
      if ($window.scrollTop() >= $window.height()) {
        $backToTop.removeClass('hidden');
      } else {
        $backToTop.addClass('hidden');
      }
    });

    $backToTop.on('click', () => {
      $body.stop().animate({
        scrollTop: 0
      }, 600);
    });

    $('.container').find('.project-images').on('click', 'a', (event) => {
      const self = $(event.target);
      const src = self.attr('src');

      const projectImages = self.closest('.project-images');
      projectImages.find('.selected').removeClass('selected');
      self.addClass('selected');
      projectImages.find('.selected-image').attr('src', src);
      $('.selected-image')[0].scrollIntoView({
        behavior: 'smooth'
      });
    });
  }
}

$(() => {
  new Engine();
});
